<script lang="ts" setup>import { toRef as _toRef, computed as _computed } from 'vue';

import { Head } from '@inertiajs/vue3'
import Header from '@consumer/modules/layout/Header.vue'
import Footer from '@consumer/modules/layout/Footer.vue'
import giftlyFavIcon from '@consumer/assets/images/favicon.ico'
import { cssPropertiesForTheme, setCurrentTheme } from '@consumer/composables/themes'
import { useSharedData, usePage } from '@consumer/composables/sharedData'
import UpdateProfileModal from '@consumer/modules/shared/UpdateProfileModal.vue'
import ModalsManager from '@consumer/modules/shared/ModalsManager.vue'
import TheNavigationProgressBar from '@corp/components/TheNavigationProgressBar.vue'
import ToastsManager from '@consumer/modules/layout/ToastsManager.vue'
import { DISCLOSURE_TEXT_WITH_LINK } from '@consumer/constants/disclosureText'
import { checkout } from '@consumer/api'
import { getYear } from 'date-fns'

defineOptions({
  inheritAttrs: false,
})

const year = getYear(new Date())

const __$temp_1 = (usePage()),
  component = _toRef(__$temp_1, 'component'),
  url = _toRef(__$temp_1, 'url');

const isCheckout = _computed(() => url.value.startsWith(checkout.create.path()))
const isFullscreen = _computed(() => component.value === 'Demo' || component.value === 'HowItWorks')

const __$temp_2 = (useSharedData()),
  currentUser = _toRef(__$temp_2, 'currentUser');

const theme = setCurrentTheme()

onMounted(() => {
  document.querySelector('.woot--bubble-holder')?.classList.add('no-percy')
})
</script>

<template>
  <Head>
    <title>Giftly: Online gift cards, printable gift cards, email gift cards, e-gift cards</title>
    <link rel="icon" :href="giftlyFavIcon">
  </Head>

  <!--
    NOTE: Layouts receive the same props as pages, so avoid using Teleport
    which can cause SSR incompatibilities.
  -->
  <div class="relative" :style="cssPropertiesForTheme(theme)">
    <Header v-if="!isFullscreen"/>
    <FlashManager/>
    <slot/>
    <div v-if="isCheckout" id="footer" class="paragraphs relative bg-grey-900 text-white text-sm">
      <GContainer class="py-6 lg:py-12 lg:pb-36">
        <div class="mb-6 flex justify-between items-center">
          <GIcon name="new-misc-giftly-logo" color="gold" style="height: 32px; width: 68px;"/>
        </div>
        <p v-html="DISCLOSURE_TEXT_WITH_LINK"/>
        <p>The Giftly Prepaid Gift is issued by Sunrise Banks N.A., St. Paul, MN 55103, Member FDIC.</p>
        <p>Copyright © {{ year }} Giftly. All rights reserved.</p>
      </GContainer>
    </div>
    <Footer v-else-if="!isFullscreen"/>
  </div>

  <ModalsManager/>
  <UpdateProfileModal v-if="currentUser?.requestProfileUpdate"/>
  <TheNavigationProgressBar/>
  <ToastsManager/>
</template>
