<script lang="ts" setup>import { toRef as _toRef, computed as _computed } from 'vue';

import TopSubNav from '@consumer/modules/layout/TopSubNav.vue'
import TopNav from '@consumer/modules/layout/TopNav.vue'
import { checkout } from '@consumer/api'
import { usePage } from '@consumer/composables/sharedData'
import { isDesktop } from '@consumer/utils/breakpoints'

const __$temp_1 = (usePage()),
  url = _toRef(__$temp_1, 'url');

const isCheckout = _computed(() => url.value.startsWith(checkout.create.path()))
</script>

<template>
  <nav class="bg-white rounded">
    <TopSubNav v-if="!isCheckout"/>
    <TopNav v-if="isDesktop || !isCheckout"/>
  </nav>
</template>

<style lang="scss">
#logged-in-dropdown {
  box-shadow: 0 3px 4px 5px rgb(0 0 0 / 5%);
  line-height: 1;
  width: 236px;
  z-index: 100;
}
</style>
