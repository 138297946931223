<script lang="ts" setup>import { toRef as _toRef } from 'vue';

import { usePage } from '@consumer/composables/sharedData'
import searchIcon from '@consumer/assets/images/search-icon.webp'

const __$temp_1 = (usePage()),
  url = _toRef(__$temp_1, 'url');

withDefaults(defineProps<{
  sidebarOpen: boolean
}>(), {  })

const emit = defineEmits<{(e: 'toggleSidebar'): void}>()

const searchPath = '/gift-cards'
</script>

<template>
  <div
    id="header-mobile-menu"
    class="lg:hidden flex items-center gap-1 h-full items-stretch"
  >
    <a v-if="!url.startsWith(searchPath)" :href="searchPath" class="p-3 flex items-center">
      <span class="sr-only">Navigate to search page</span>
      <img
        id="mobile_nav_search_icon_tap"
        :src="searchIcon"
        alt="Search"
        width="19"
        height="17"
        class="clickable"
      >
    </a>
    <button
      ref="hamburgerMenuBtn"
      class="p-3 hover:bg-gray-100"
      :class="{ 'opacity-0': sidebarOpen }"
      @click="emit('toggleSidebar')"
    >
      <span class="sr-only">Open main menu</span>
      <GIcon name="menu"/>
    </button>
  </div>
</template>
