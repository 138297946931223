<script lang="ts" setup>import { ref as _ref } from 'vue';

import MobileSidebar from '@consumer/modules/layout/MobileSidebar.vue'
import TopNavGiftlyLogo from '@consumer/modules/layout/TopNavGiftlyLogo.vue'
import TopNavDesktop from '@consumer/modules/layout/TopNavDesktop.vue'
import TopNavMobile from '@consumer/modules/layout/TopNavMobile.vue'

const sidebarOpen = _ref(false)
</script>

<template>
  <MobileSidebar :open="sidebarOpen" @close="sidebarOpen = false"/>

  <div id="header" class="relative">
    <GContainer class="h-65px lg:h-85px !px-8px !py-10px flex items-center justify-between font-normal">
      <TopNavGiftlyLogo/>
      <div class="flex justify-between items-center h-full">
        <TopNavDesktop/>
        <TopNavMobile :sidebarOpen="sidebarOpen" @toggleSidebar="sidebarOpen = !sidebarOpen"/>
      </div>
    </GContainer>
  </div>
</template>
